export const siteConfig = {
  "name": "Magical Bakery",
  "slogan": "slogan",
  "domain": "https://magicalbakery.site",
  "icon": "/favicon.ico",
  "appleIcon": "/apple-touch-icon.png",
  "theme": {
    "headFont": "font-leckerli",
    "primaryColor": "purple-500",
    "fontColor": "text-gray-900",
    "backgroundColor": "bg-white"
  },
  "gaId": "G-5B5HR0FQXP",
  "plausible": "",
  "navbarItems": [
    {
      "title": "home",
      "href": "/"
    },
    {
      "title": "guide",
      "href": "/guide"
    },
    {
      "title": "information",
      "href": "/t"
    }
  ],
  "gameType": "download",
  "gameIframeUrl": "",
  "gameDownloadBg": "",
  "gameDownloadUrl": "https://store.steampowered.com/app/2595940/Magical_Bakery/",
  "isShowFAQs": true,
  "isShowVideo": true,
  "isShowGuide": true,
  "videos": [
    {
      "title": "Magical Bakery",
      "url": "https://www.youtube.com/embed/2FKGAvyrDwY?si=PUYtNkY6tAJqmcKY"
    }
  ],
  "isShowComments": true,
  "isShowTweets": false,
  "useRealTweets": null,
  "tweets": [
    ""
  ],
  "comments": [
    {
      "author": "Lorenz S.",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lorenz S..png",
      "content": "This game is so magical! The graphics are amazing and the gameplay is addictive. I love the fact that you can bake magical treats and use them to help customers in their quests. The characters are\\\\u53ef\\\\u7231\\\\u53ef\\\\u7231\\\\u7684\\\\u53ef\\\\u7231 and the story is engaging. I highly recommend this game to anyone who loves baking or fantasy games!"
    },
    {
      "author": "MK",
      "role": "player",
      "avatar": "https://api.multiavatar.com/MK.png",
      "content": "I'm not usually one for baking games, but this one is\\\\u771f\\\\u7684\\\\u5f88\\\\u6709\\\\u8da3\\\\uff01The graphics are colorful and the gameplay is easy to pick up. I love the fact that you can decorate your bakery and make it look however you like. The recipes are delicious and the challenges are addictive. This game is a definite winner!"
    },
    {
      "author": "Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc.png",
      "content": "I've been playing this game for hours and I'm not even close to getting bored! The levels are challenging but not impossible, and the graphics are beautiful. I especially love the fact that you can design your own bakery and choose your own recipes. This game is a true gem!"
    },
    {
      "author": "Packy McCormick",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Packy McCormick.png",
      "content": "This game is so cute! The graphics are colorful and the characters are\\\\u53ef\\\\u7231\\\\u53ef\\\\u7231\\\\u7684\\\\u53ef\\\\u7231. I love the fact that you can bake cupcakes and decorate them with frosting and sprinkles. The gameplay is easy to learn but challenging to master. This game is perfect for kids and adults alike!"
    },
    {
      "author": "Lex Fridman",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lex Fridman.png",
      "content": "I'm a huge fan of management games, and this one is definitely one of the best. The graphics are beautiful and the gameplay is addictive. I love the fact that you can decorate your bakery and make it look however you like. The recipes are delicious and the challenges are fun. This game is a must-have for any fan of management games!"
    },
    {
      "author": "Mike Taylor",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Mike Taylor.png",
      "content": "I've been playing this game for days and I still haven't finished it! The levels are long and challenging, but that's what makes them so fun. I especially love the fact that you can design your own bakery and choose your own recipes. This game is a true masterpiece!"
    }
  ],
  "isShowRecommendation": false,
  "friendLinks": [
    {
      "title": "",
      "url": ""
    }
  ],
  "recommendations": {
    "en": [
      {
        "title": "",
        "cover": "",
        "url": ""
      }
    ]
  },
  "faviconBgColor": null,
  "faviconTextColor": null,
  "faviconText": "MB"
};
